[agc-mousewheel] {
  overflow: hidden !important;
}

[mat-dialog-title] {
  padding-bottom: 24px !important;
}

mat-dialog-content {
  padding: 20px 24px 20px 24px !important;
}

.agc-disabled {
  background-color: #F8F8F8;
  cursor: not-allowed !important;

  &:any-link, a:any-link {
    pointer-events: none !important;
  }
}

.agc-error {
  color: #F00;
  font-size: 11px;
}

.table-text {
  font-size: 12px;
}

.theme-loader {
  opacity: .5;
}

.b-b {
  border-bottom: 1px solid rgba(0,0,0,.125) !important;
}

.pcoded[theme-layout=vertical] {
  .pcoded-navbar {
    top: 0px !important;
    bottom: 0px !important;

    @media (min-width: 768px) {
      top: 56px !important;
      height: auto !important;
    }
  }
}


.cdk-overlay-container {
  z-index: 2000 !important;
}

.cdk-global-scrollblock {
  position: static !important;
  overflow-y: visible !important;

  > body {
    overflow-y: hidden !important;
  }
}

.agc-frame-menu {
  display: block;
  list-style-type: none;
  border: 1px solid;
  border-color: rgba(0, 0, 0, .1);
  border-left: none;
  border-right: none;
  margin: 0px -20px;
  padding: 0px;

  > li {
    display: block;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid;
    border-color: inherit;
    position: relative;

    &:last-child {
      border-bottom: none;
    }

    > a {
      display: block;
      padding: 15px 20px;

      &, &:hover {
        color: #333 !important;
      }
    }

    &:hover {
      background-color: #F8F8F8;
    }

    &.active {
      &, > a {
        font-weight: bold;
        background-color: #eaeaea !important;
      }
    }

    &.active::after {
      display: block;
      position: absolute;
      background-color: inherit;
      transform: rotate(45deg) translateY(-50%);
      content: '';
      width: 20px;
      height: 20px;
      right: 0px;
      top: 50%;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
      z-index: -1;
    }
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.agc {
  &-table {
    th, td {
      white-space: normal;
    }
  }

  &-wrap { white-space: normal !important; }
  &-nowrap { white-space: nowrap !important; }
}

label {
  margin-bottom: 0px !important;
}

.reg-box {
  width: 90%;
  max-width: 900px !important;
  margin: 20px auto 0px;
}

[spacer] {
  margin: auto;
}
